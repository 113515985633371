import { CartStatus } from '../../features/Cart/types';

const CART_TIMEOUT = 60 * 60 * 1000; // 60 minutes in ms
const CART_TIMEOUT_THRESHOLD = 55 * 60 * 1000; // 55 mins or 5 min before cart timeout

const getCartStatus = (
  lastModified: Date
): CartStatus.EXPIRED | CartStatus.EXPIRING | CartStatus.ACTIVE => {
  const currentTime = Date.now();
  const lastModifiedDate = new Date(lastModified);

  if (currentTime - lastModifiedDate.getTime() < CART_TIMEOUT_THRESHOLD) {
    return CartStatus.ACTIVE;
  }

  if (
    currentTime - lastModifiedDate.getTime() > CART_TIMEOUT_THRESHOLD &&
    currentTime - lastModifiedDate.getTime() < CART_TIMEOUT
  ) {
    return CartStatus.EXPIRING;
  }

  return CartStatus.EXPIRED;
};

export default getCartStatus;
